/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Badge from 'react-bootstrap/Badge';
import NbioApi from "../../lib/api/NbioApi";
import {money} from "../../lib/money/money";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/cjs/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import dayjs from "dayjs";
import WSSClient from "../../lib/wss/wss-client";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner} from "react-bootstrap";
import * as Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import DropdownItem from "react-bootstrap/DropdownItem";
import Form from "react-bootstrap/Form";

//STYLE
import "../../scss/components/orders.scss";

//COMPONENTS
import OrderItems from "./OrderItems";
import Avatar from "../ui/Avatar";
import ProgressBarStatus from "../ui/ProgressBarStatus";
import OrderRefunds from "./OrderRefunds";
import StaticMapDashboard from "../ui/StaticMapDashboard";
import MPStatus from "../ui/MPStatus";
import OrderCoupons from "./OrderCoupons";
import OrderDiscounts from "./OrderDiscounts";
import ProductSelector from "../products/ProductSelector";
import OrderParcels from "./OrderParcels";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";

//CONST
const nanoid       = require('nanoid');
const AUTO_RELOAD_MS = 20 * 1000; //each 20 seconds
const {parcels} = require('../../lib/parcels/parcels');

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: "",
            lastUpdateDate: null,
            order: {
                status: 'pending',
                shipping: {},
                shippingMethod: {},
                user: {},
                logs: [],
                items: [],
                deliveryBlock: null,
                requiresInvoice:false,
                invoiceData:{

                },
                itemsLog:[],
                parcels:[],
                setFreeShipping:{}

            },
            operators: [],
            isReportingLive: null,
            currentTemperature:'-',
            currentHumidity:'-',
            currentTemperature2:'-',
            currentHumidity2:'-',
            lastReportTimeStamp:null,
            isWebSocketConnected:false,
            downloadingPDF:false,
            discounts:[],
            editMode:false,
            addProductMode:false,
            selectedProduct:{},
            isSavingEdits:false,
            showChangesModal:false,
            parcelNumber:'',
            parcelName:'',
            isAddingParcel:false
        }

        this.loadOrder      = this.loadOrder.bind(this);
        this.getOperators   = this.getOperators.bind(this);
        this.assignOperator = this.assignOperator.bind(this);
        this.wssOnMessage   = this.wssOnMessage.bind(this);
        this.connectWSS     = this.connectWSS.bind(this);
        this.cancelOrder    = this.cancelOrder.bind(this);
        this.startAutoReload               = this.startAutoReload.bind(this);
        this.stopAutoReload                = this.stopAutoReload.bind(this);

    }

    startAutoReload(){
        if(!this.autoReloadInterval){
            this.loadOrder();
            this.autoReloadInterval =  setInterval(() =>{
                this.loadOrder();
            },AUTO_RELOAD_MS)
        }else{
        }

    }

    stopAutoReload(){
        clearInterval(this.autoReloadInterval)
    }

    componentWillUnmount() {
        this.stopAutoReload();
    }

    wssOnMessage(event) {
        if(this.state.editMode){
            return false; // Do nothing when the user is editing
        }
        let jsonEvent = {}
        try {
            jsonEvent = JSON.parse(event.data);
        } catch (e) {

        }
        switch (jsonEvent.nbioEvent) {
            case WSSClient.NBIO_EVENTS.ORDER_CREATED:
                return -1
                break;
            case WSSClient.NBIO_EVENTS.ORDER_UPDATED:
                // console.log(jsonEvent)
                if (jsonEvent.data.orderId === this.state.orderId) {
                    this.loadOrder();
                }
                break;
            case WSSClient.NBIO_EVENTS.ORDER_CANCELLED:
                // console.log(jsonEvent)
                if (jsonEvent.data.orderId === this.state.orderId) {
                    this.loadOrder();
                }
                break;
            case WSSClient.NBIO_EVENTS.DATA_DEVICE_UPDATED:
                this.setState({isReportingLive: true});
                const currentTemperature = jsonEvent.data.deviceData.T0 || this.state.currentTemperature;
                const currentHumidity = jsonEvent.data.deviceData.H0 || this.state.currentHumidity;
                const currentTemperature2 = jsonEvent.data.deviceData.T1 || this.state.currentTemperature2;
                const currentHumidity2 = jsonEvent.data.deviceData.H1 || this.state.currentHumidity2;
                this.setState(
                    {
                        currentTemperature:currentTemperature,
                        currentHumidity:currentHumidity,
                        currentTemperature2:currentTemperature2,
                        currentHumidity2:currentHumidity2,
                        lastReportTimeStamp:jsonEvent.data.deviceData.created_at
                    })
            default:
                return -1;
        }
    }

    componentDidMount() {
        const orderId = this.props.match.params.id;
        this.setState({orderId: orderId});
        this.loadOrder();
        this.connectWSS();
        this.getOperators();
    }

    connectWSS() {
        const _this = this;
        WSSClient.startListening().then((socket) => {
            socket.onopen = function(){
                if(_this.state.editMode){
                    return false; // Do nothing when the user is editing
                }
                // reload just in case some order got in-between
                _this.loadOrder();
                _this.stopAutoReload();
                _this.setState({
                    isWebSocketConnected:true
                })
            }
            socket.addEventListener('open', () => {
                _this.requestLiveData()
            })
            socket.addEventListener('message', this.wssOnMessage);
            socket.addEventListener('close', () => {
                if(_this.state.editMode){
                    return false; // Do nothing when the user is editing
                }
                this.startAutoReload();
                this.setState({
                    isWebSocketConnected:false
                })
                socket.removeEventListener('message', this.wssOnMessage);
                setTimeout(() => {
                    if(_this.state.editMode){
                        return false; // Do nothing when the user is editing
                    }
                    _this.connectWSS();
                    _this.loadOrder();
                }, 3000)
            })
        }).catch((ex) => {
            this.setState({
                isWebSocketConnected:false
            })
            setTimeout(() => {
                if(_this.state.editMode){
                    return false; // Do nothing when the user is editing
                }
                _this.connectWSS();
                _this.loadOrder();
            }, 3000)
        })
    }

    requestLiveData() {
        const orderId = this.props.match.params.id;

        NbioApi.orders.requestLiveData(orderId).then((res) => {

        }).catch((ex) => {

        })
    }

    loadOrder() {
        const orderId = this.props.match.params.id;
        NbioApi.orders.getOrder(orderId).then((res) => {
            const discounts = res.data.order.items.map((i) => i.discount).filter((i) => i);
            this.setState({
                order: res.data.order,
                lastUpdateDate: new Date(),
                discounts: discounts
            })
        }).catch((ex) => {

        })
    }

    getOperators() {
        NbioApi.users.getUsers('operator').then((res) => {
            this.setState({
                operators: res.data.users
            })
        }).catch((ex) => {

        })
    }

    hasControlledMedication = (order) => {
        const items = order.items ? order.items : []
        if (items.some(e => e.isControlled)) {
            return <Badge pill className="float-right" bg="warning">Contiene medicamento controlado</Badge>
        }
    }

    assignOperator = (uid) => {
        const orderId = this.state.orderId;
        NbioApi.orders.assignOrder(orderId, uid).then(res => {
            this.loadOrder(orderId);
        }).catch(ex => {
            console.log(ex);
        });
    }

    markOrderAsFinished() {
        const orderId = this.state.orderId;
        NbioApi.orders.markOrderAsFinished(orderId).then((res) => {
            this.loadOrder();
        }).catch((ex) => {

        });
    }

    isAssignDisabled(order) {
        // if the order is finished, can't assign
        if (order.status === 'finished') {
            return true
        }
        // if the order requires a prescription
        // the order can not be assigned until it's validated
        if (order.requiresPrescription) {
            // const controlledItems
            const controlledItems     = order.items.filter(item => item.isControlled);
            const isAllItemsValidated = !controlledItems.map((item) => item.isPrescriptionValidated).includes(false);

            if (order.isPrescriptionValid && isAllItemsValidated) {
                // OK, Assign
                return false;
            } else {
                return true;
            }
        } else {
            // OK, Assign
            return false
        }
    }

    formatDate(date) {
        if (!date) {
            return '-';
        }
        return dayjs(date).format('DD MMM YYYY - HH:mm:ss');
    }
    saveItems(){
        const orderId = this.props.match.params.id;
        this.setState({isSavingEdits:true});
        NbioApi.orders.editItems(orderId,this.state.order.items).then((res) =>{
            this.loadOrder();
        }).catch((ex) =>{
            let errorMsg = 'Hubo un error al modificar los productos';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {
            }
            Swal.fire({
                title: 'Error al modificar productos',
                html: errorMsg,
                icon: 'error',
                heightAuto:false
            });
            this.loadOrder();

        }).finally((ex) =>{
            this.setState({isSavingEdits:false,editMode:false});
        });

    }

    renderDataDevice() {
        const status = this.state.order.status;
        if(['on_route','delivering'].includes(status) && this.state.isReportingLive) {
            return (
                <div>
                    <div style={{backgroundColor: 'red', padding: 10, borderRadius: 5, display: 'inline-block'}}>
                        <Spinner animation={'grow'} variant={"white"} size={'sm'}>
                        </Spinner>
                        <span style={{color: 'white', marginLeft: 5}}>En vivo</span>
                    </div>
                    <div className={'d-flex align-items-center mt-2'}>
                        <Card border={'secondary'}  >
                            <Card.Body className={'d-flex flex-column align-items-center'} style={{minWidth: '175px'}}>
                                <FontAwesomeIcon icon="temperature-high" size={'lg'} color={'#00b2e3'}/>
                                <div className={'d-flex mt-2'}>
                                    <div><b>T0: {this.state.currentTemperature} °C</b></div>
                                    <div className={'ml-2'}><b>T1: {this.state.currentTemperature2} °C</b></div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card border={'success'} className={'ml-2'} style={{minWidth: '175px'}}>
                            <Card.Body className={'d-flex flex-column align-items-center'}>
                                <FontAwesomeIcon icon="water" size={'lg'} color={'#2dd36f'}/>
                                <div className={'d-flex mt-2'}>
                                    <div><b>H0: {this.state.currentHumidity} %</b></div>
                                    <div className={'ml-2'}><b>H1: {this.state.currentHumidity2} %</b></div>
                                </div>
                            </Card.Body>
                        </Card>

                        <div>
                            <div style={{padding: 10, borderRadius: 5, display: 'inline-block'}}>
                                <FontAwesomeIcon icon="clock"/>
                                <span className={'ml-2'}><b>{this.formatDate(this.state.lastReportTimeStamp)}</b></span>
                            </div>
                            <a href={`/orders/${this.state.order._id}/tracking`}>
                                <Button variant={'link'}>
                                    Más información
                                </Button>
                            </a>

                        </div>
                    </div>
                </div>

            )
        }else if(['delivered','finished'].includes(status)){
            return (
                <div className={'d-flex'}>
                    <div style={{backgroundColor: 'gray', padding: 10, borderRadius: 5,display:'inline-block'}}>
                        <span style={{color: 'white', marginLeft: 5}}>No disponible</span>
                    </div>
                    <a href={`/orders/${this.state.order._id}/tracking`}>
                        <Button variant={'link'}>
                            Ver histórico
                        </Button>
                    </a>
                </div>
            )
        }else{
            return (
                <div className={'d-flex'}>
                    <div style={{backgroundColor: 'gray', padding: 10, borderRadius: 5,display:'inline-block'}}>
                        <span style={{color: 'white', marginLeft: 5}}>No disponible</span>
                    </div>
                    <a href={`/orders/${this.state.order._id}/tracking`}>
                        <Button variant={'link'}>
                            Más información
                        </Button>
                    </a>
                </div>
            )
        }
    }

    print(){

    }

    cancelOrder(){
        const orderId            = this.props.match.params.id;
        Swal.fire({
            icon:"warning",
            title:'Cancelar orden',
            html:'¿Estás seguro de cancelar la orden? El cliente recibirá un reembolso total en caso de haber pagado con tarjeta de crédito.',
            cancelButtonText: 'No',
            confirmButtonColor: '#f32735',
            confirmButtonText: 'Si, cancelar',
            showCancelButton: true,
            heightAuto:false
        }).then((res) =>{
            if(res.isConfirmed){
                Swal.fire({
                    title:'Escribe una razón para la cancelación',
                    html:'Lo que ingreses a continuación será visible para el cliente',
                    input: 'text',
                    confirmButtonColor: '#f32735',
                    confirmButtonText: 'Cancelar orden',
                    preConfirm: (reason) => {
                        if(!reason){
                            Swal.showValidationMessage(
                                `La razón de cancelación no puede estar vacía`
                            )
                        }else{
                            return reason;
                        }
                    },
                    heightAuto:false
                }).then((res) =>{
                    if(res.isConfirmed) {
                        const reason = res.value
                        NbioApi.orders.cancelOrder(orderId,reason).then((res) =>{
                            Swal.fire({
                                title:'Orden cancelada',
                                icon:'success',
                                heightAuto:false
                            })
                            this.loadOrder();
                        }).catch((ex) =>{
                            let errorMsg = 'Hubo un error al cancelar la orden';
                            try {
                                errorMsg = ex.response.data.error_es;
                            } catch (ex) {

                            }
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al cancelar la orden',
                                html: errorMsg,
                                confirmButtonColor: '#2a7de1',
                                heightAuto:false
                            })
                            this.loadOrder();
                        })
                    }
                })

            }

        })
    }

    downloadPDF(){
        if(!this.state.downloadingPDF){
            const orderId            = this.props.match.params.id;
            this.setState({downloadingPDF:true}, ()=>{
                NbioApi.orders.downloadOrder(orderId).then((res) =>{
                    this.setState({
                        isDownloading: false
                    })
                    const url  = window.URL.createObjectURL(
                        new Blob([res.data],
                            {
                                type: 'application/pdf'
                            }))
                    const link = document.createElement('a');
                    link.href  = url;
                    link.setAttribute('download', `${orderId}.pdf`);
                    link.setAttribute('target','_blank');
                    document.body.appendChild(link);
                    link.click();
                    this.setState({downloadingPDF:false});
                }).catch((ex) =>{
                    this.setState({downloadingPDF:false});
                })
            });
        }


    }

    switchEditMode(){
        if(this.state.editMode){
            this.loadOrder();
        }
        this.setState({editMode:!this.state.editMode });
    }

    decreaseQty(row,index){
        let order = {...this.state.order};
        let items = [...order.items];
        items[index].quantity = items[index].quantity - 1;
        order.items = items;
        this.setState({order:order});
    }

    increaseQty(row,index){
        let order = {...this.state.order};
        let items = [...order.items];
        items[index].quantity = items[index].quantity + 1;
        order.items = items;
        this.setState({order:order});
    }

    onChangeQty(row,index,value){
        let qty = value;
        if(isNaN(qty)){
            qty = 1;
        }
        let order = {...this.state.order};
        let items = [...order.items];
        items[index].quantity = qty;
        order.items = items;
        this.setState({order:order});
        const inputId = `qty_input_${index}`;
        setTimeout(() =>{
            document.getElementById(inputId).focus();
        },200);
    }

    onDeleteItem(row,index){
        let order = {...this.state.order};
        let items = [...order.items];
        items.splice(index, 1);
        order.items = items;
        this.setState({order:order});
    }

    onProductSelected2(product){
        this.setState(
            {selectedProduct: product}
        )
    }

    onProductSelected(product){
        let images = Array.isArray(product.images) ? product.images :[];
        const imageUrl = images.length > 0 ? images[0] : '';
        const _item = {
            quantity: 1,
            imageUrl: imageUrl,
            imageUrlThumbnail: imageUrl,
            description: product.name,
            price: product.price,
            displayPrice:product.price,
            cartItemId: nanoid.nanoid(),
            SKU: product.sku,
            isControlled:product.isControlled,
            medicationGroup:product.medicationGroup,
            refrigeration:product.refrigeration,
            categories:product.categories,
            validatedPrice:0,
            discount:product.discount
        }
        let order = {...this.state.order};
        let items = [...order.items];
        items.push(_item);
        order.items = items;
        this.setState({order:order});
    }

    addParcel(){
        this.setState({
            isAddingParcel:true
        });
        const parcelTrackingLink = parcels.find((p) => p.name === this.state.parcelName)
                .generateTrackingUrl(this.state.parcelNumber);
        NbioApi.orders.addParcel(
            this.state.order._id,
            this.state.parcelName,
            this.state.parcelNumber,
            parcelTrackingLink
        ).then((res) =>{
            this.setState({
                parcelNumber:'',
                parcelName:''
            });
           this.loadOrder();
        }).catch((ex) =>{

        }).finally(() =>{
            this.setState({
                isAddingParcel:false,
            });
        });
    }

    render() {
        const orderId            = this.props.match.params.id;
        const order              = this.state.order;
        const assignedUser       = order.assignedUser ? order.assignedUser : {};
        const operators          = this.state.operators;
        const logs               = this.state.order ? this.state.order.logs.reverse() : [];
        const tickets            = order.tickets ? order.tickets : [];
        const txtTooltipFinished = order.status === 'finished' ? 'La orden ya ha sido TERMINADA.' : 'La orden tiene que ser ENTREGADA para poder terminarla.';
        const txtTooltipAssign   = order.status === 'finished' ? 'La orden ya ha sido TERMINADA.' : 'Valida la receta para poder asignar al operador.';
        const shipping           = order.shipping ? order.shipping : {};
        const _region            = order.region || {};
        const user               = order.user || {};
        const regionStyle = {
            backgroundColor: _region.color,
            color:'white'
        }
        let txtTooltipAmount = '';
        if (order.status === 'finished') {
            txtTooltipAmount = 'La orden ya ha sido TERMINADA.';
        } else if (tickets.length === 0) {
            txtTooltipAmount = 'El operador no ha agregado recibos para validar.';
        } else {
            txtTooltipAmount = 'El monto ya ha sido validado';
        }
        const deliveryBlock = this.state.order.deliveryBlock || {};
        const displayTotals = this.state.order.displayTotals || {};
        const selectedProduct = this.state.selectedProduct;
        const sPImages = Array.isArray(selectedProduct.images) ? selectedProduct.images : []
        const sPImage = sPImages.length > 0 ? sPImages[0] : '';
        const itemsLog = Array.isArray(this.state.order.itemsLog) ? this.state.order.itemsLog  : [];
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3 d-flex flex-column'>
                        <div className={'d-flex'}>
                            <h5>Detalles de orden: <span className={'text-black-50'}>#{this.state.orderId}</span></h5>
                            <ButtonGroup className={'ms-auto'}>
                                <Button onClick={() => this.loadOrder()}>
                                    <FontAwesomeIcon icon={'sync-alt'}>
                                    </FontAwesomeIcon>
                                </Button>
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            {
                                                this.state.isWebSocketConnected ? 'Datos en tiempo real' : 'Los datos no están en tiempo real. Actualizando cada 20 segundos.'
                                            }
                                        </Tooltip>
                                    }>
                                    <Button variant={'outline-info'} >
                                        <FontAwesomeIcon icon={'circle'} color={this.state.isWebSocketConnected ? 'green' : 'gray'} />
                                    </Button>
                                </OverlayTrigger>
                                <DropdownButton variant="dark" className={'ms-auto text-uppercase'} title={'OPCIONES'}>
                                    <Dropdown.Item className={'text-uppercase'} onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.downloadPDF();
                                    }}>
                                        <FontAwesomeIcon icon={'print'}></FontAwesomeIcon>
                                        &nbsp;Imprimir
                                        { this.state.downloadingPDF ? <Spinner animation={'border'} color={'primary'} size={'sm'}></Spinner> : null}
                                    </Dropdown.Item>
                                    <Dropdown.Item className={'text-uppercase text-danger'} onClick={this.cancelOrder}>Cancelar
                                        orden</Dropdown.Item>
                                </DropdownButton>
                            </ButtonGroup>
                        </div>


                        <div>Última actualización: <b>{this.formatDate(this.state.lastUpdateDate)}</b></div>

                    </Col>
                </Row>

                <Row>
                    <Col className='py-3'>
                        <Card.Body>
                            <ProgressBarStatus status={order.status}></ProgressBarStatus>
                        </Card.Body>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>
                                    <span>Información general</span>
                                    {this.hasControlledMedication(this.state.order)}
                                </Card.Title>
                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        Tipo de entrega
                                    </div>
                                    <div className={'amount'}>
                                        {this.state.order.shippingMethod.name}
                                        {
                                            deliveryBlock.dayLabel ?
                                                <div className={'text-primary'}>
                                                    ({deliveryBlock.dayLabel} - {deliveryBlock.hoursLabel})
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        Método de pago
                                    </div>
                                    <div className={'amount'}>
                                        {this.renderPaymentMethod()}
                                    </div>
                                </div>
                                <NPIf condition={this.state.order.paymentMethod === 'cash'}>
                                    <div className={'t-row d-flex'}>
                                        <div className={'title'}>
                                            ¿Necesita cambio?
                                        </div>
                                        <div className={'amount'}>
                                            {this.state.order.cash_needs_change ? 'Si' : 'No'}
                                        </div>
                                    </div>
                                    <NPIf condition={this.state.order.cash_needs_change}>
                                        <div className={'t-row d-flex'}>
                                            <div className={'title'}>
                                                ¿Con cuánto paga?
                                            </div>
                                            <div className={'amount'}>
                                                {money(this.state.order.cash_change_amount)}
                                            </div>
                                        </div>
                                    </NPIf>
                                </NPIf>
                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        Estado del pago
                                    </div>
                                    <div className={'amount'}>
                                        {this.renderPaymentStatus()}
                                    </div>
                                </div>
                                <hr/>
                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        ¿Requiere Factura?
                                    </div>
                                    <div className={'amount'}>
                                        {this.renderInvoice()}
                                    </div>
                                </div>
                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        Estado de la orden
                                    </div>
                                    <div className={'amount'}>
                                        {this.renderOrderStatus()}
                                    </div>
                                </div>
                                <div className={'t-row d-flex mb-3'}>
                                    <div className={'title'}>
                                        Recibos
                                    </div>
                                    <NPIf condition={tickets.length > 0}>
                                        <div className={'amount'}>
                                            <a href={`${orderId}/view-tickets`}>
                                                {tickets.length} recibos agregados
                                            </a>
                                        </div>
                                        <NPElse>
                                            <div className={'amount'}>
                                                No hay recibos agregados por el operador
                                            </div>
                                        </NPElse>
                                    </NPIf>
                                </div>

                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        Operador asignado
                                    </div>
                                    <NPIf condition={assignedUser.uid}>
                                        <div className={'amount text-capitalize'}>
                                            {assignedUser.name} {assignedUser.last_name} ({assignedUser.email})
                                        </div>
                                        <NPElse>
                                            <div className={'amount text-capitalize'}>
                                                El operador no ha sido asignado
                                            </div>
                                        </NPElse>
                                    </NPIf>
                                </div>

                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        Teléfono del operador
                                    </div>
                                    <div className={'amount'}>
                                        {assignedUser.phone}
                                    </div>
                                </div>
                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        Región
                                    </div>
                                    <div className={'amount'}>
                                        <span className={'badge'} style={regionStyle}>
                                            {_region.name}
                                        </span>
                                    </div>
                                </div>
                            </Card.Body>
                            <Card.Footer className={'d-flex justify-content-end'}>
                                <ButtonGroup>
                                    {/*Render assign operator btn. Tooltip on disabled*/}
                                    <NPIf condition={this.isAssignDisabled(order)}>
                                        <OverlayTrigger
                                            overlay={<Tooltip id="tooltip-disabled">{txtTooltipAssign}</Tooltip>}>
                                          <span className="d-inline-block">
                                               <DropdownButton as={ButtonGroup}
                                                               variant="outline-primary"
                                                               className={'text-uppercase'}
                                                               disabled={true}
                                                               style={{pointerEvents: 'none'}}
                                                               title={'ASIGNAR OPERADOR'}>
                                                </DropdownButton>
                                          </span>
                                        </OverlayTrigger>
                                        <NPElse>
                                            <DropdownButton as={ButtonGroup}
                                                            variant="primary"
                                                            className={'text-uppercase'}
                                                            title={'ASIGNAR OPERADOR'}>
                                                {
                                                    operators.map(operator => {
                                                        return (
                                                            <Dropdown.Item key={operator.uid}
                                                                           className={'text-capitalize'}
                                                                           onClick={() => this.assignOperator(operator.uid)}>
                                                                {operator.name} {operator.last_name}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                        </NPElse>
                                    </NPIf>
                                    {/*Render amount validated btn. Tooltip on disabled*/}
                                    <NPIf
                                        condition={tickets.length === 0 || ['amount_validated', 'on_route', 'delivering', 'delivered', 'finished'].includes(order.status)}>
                                        <OverlayTrigger overlay={<Tooltip
                                            id="tooltip-amount-disabled">{txtTooltipAmount}</Tooltip>}>
                                              <span className="d-inline-block">
                                                    <Button className={'text-uppercase'}
                                                            variant={'outline-secondary'}
                                                            style={{pointerEvents: 'none'}}
                                                            disabled={true}>
                                                        VALIDAR MONTO
                                                    </Button>
                                              </span>
                                        </OverlayTrigger>
                                        <NPElse>
                                            <Button className={'text-uppercase'}
                                                    variant={'secondary'}
                                                    onClick={() => this.props.history.push(`/orders/${orderId}/validate-amount`)}>
                                                VALIDAR MONTO
                                            </Button>
                                        </NPElse>
                                    </NPIf>
                                    {/*Render finished btn. Tooltip on disabled*/}
                                    <NPIf condition={order.status !== 'delivered'}>
                                        <OverlayTrigger overlay={<Tooltip
                                            id="tooltip-finished-disabled">{txtTooltipFinished}</Tooltip>}>
                                              <span className="d-inline-block">
                                                  <Button className={'text-uppercase'}
                                                          variant={'outline-success'}
                                                          disabled={true}
                                                          style={{pointerEvents: 'none'}}>
                                                        MARCAR COMO TERMINADO
                                                  </Button>
                                              </span>
                                        </OverlayTrigger>
                                        <NPElse>
                                            <Button className={'text-uppercase'}
                                                    variant={'success'}
                                                    onClick={() => this.markOrderAsFinished()}>
                                                MARCAR COMO TERMINADO
                                            </Button>
                                        </NPElse>
                                    </NPIf>
                                </ButtonGroup>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>Temperatura y humedad</Card.Title>
                                {
                                        this.renderDataDevice()
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className={'mt-4'}>

                    <Col>
                        <Card style={{overflowY:'auto'}}>
                            <Card.Body>
                                <Card.Title className={'text-capitalize d-flex'}>
                                    Productos
                                    {/*EDIT ORDER*/}
                                    <Button  disabled={this.state.isSavingEdits} variant={'outline-primary ml-2'} size='sm' onClick={() => this.switchEditMode()}>
                                        <FontAwesomeIcon icon={this.state.editMode  ? 'unlock' : 'lock'}/>
                                    </Button>

                                    {
                                        this.state.editMode && !this.state.addProductMode ?
                                            <Button disabled={this.state.isSavingEdits} variant={'outline-primary ml-2'} size='sm' onClick={() => this.setState({addProductMode:true})}>
                                                <FontAwesomeIcon icon={'plus'}/>
                                            </Button>
                                        : null
                                    }
                                    {
                                        this.state.editMode  ?
                                            <div className={'ml-2 '}>

                                                <Button
                                                    variant={'outline-primary ml-2'}
                                                    size='sm'
                                                    onClick={() => {this.saveItems()}}
                                                    disabled={this.state.isSavingEdits}

                                                >
                                                    {
                                                        this.state.isSavingEdits ?
                                                        <div>
                                                            <Spinner size={'sm'}></Spinner>
                                                             <>&nbsp;Guardando...</>
                                                        </div> : <><FontAwesomeIcon icon={'save'}/>&nbsp;Guardar Cambios</>
                                                    }
                                                </Button>
                                            </div>
                                            : null
                                    }

                                </Card.Title>
                                <div>
                                    {
                                        itemsLog.length > 0 ?
                                            <div>
                                                <small>
                                                    *Esta orden ha sido modificada.
                                                    <Button variant={'link'}
                                                            onClick={() => this.setState({showChangesModal:true})}
                                                    > Ver cambios.
                                                    </Button>
                                                </small>
                                            </div> : null
                                    }
                                </div>
                            </Card.Body>
                            <NPIf condition={this.state.editMode}>
                                <OrderItems order={this.state.order}
                                            onReloadRequest={this.loadOrder}
                                            editMode={this.state.editMode}
                                            onDecreaseQty={(row,index) => this.decreaseQty(row,index)}
                                            onIncreaseQty={(row,index) => this.increaseQty(row,index)}
                                            onChangeQty={(row,index,value) => this.onChangeQty(row,index,value)}
                                            onDeleteItem={(row,index) => this.onDeleteItem(row,index)}
                                ></OrderItems>
                            </NPIf>
                            <NPIf condition={!this.state.editMode}>
                                <OrderItems order={this.state.order} onReloadRequest={this.loadOrder} editMode={this.state.editMode}></OrderItems>
                            </NPIf>
                            <Modal fullscreen={true} show={this.state.addProductMode} onHide={() => this.setState({addProductMode:false})}>
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Agregar Producto
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <ProductSelector
                                        onProductSelected={(p) => this.onProductSelected2(p)}
                                        clearSeach={true}
                                    >
                                    </ProductSelector>
                                    {
                                        this.state.selectedProduct.name ?
                                            <div className={'mt-3'}>
                                                <h6>
                                                    Producto seleccionado
                                                </h6>
                                                <div className={'pl-item'}>
                                                    <img src={sPImage} height={'30px'}/>
                                                    <div>
                                                        <div className={'pl-item-text'}>
                                                            {selectedProduct.name}
                                                        </div>
                                                        <div className={'pl-item-text pl-item-gray'}>
                                                            {selectedProduct.sku}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                    }

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button disabled={!this.state.selectedProduct.name}
                                            onClick={() => {
                                                this.setState({addProductMode:false})
                                                this.onProductSelected(this.state.selectedProduct)
                                            }}>
                                        Agregar producto</Button>
                                </Modal.Footer>
                            </Modal>
                            {/*Modified products Modal*/}
                            <Modal fullscreen={true} show={this.state.showChangesModal} onHide={() => this.setState({showChangesModal:false})}>
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        Cambios de productos
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {
                                        itemsLog.map((log) =>{
                                            const items = log.items || [];
                                            return(
                                                <div key={log._id}>
                                                    <h6>Fecha de modificación:</h6>
                                                    <div>{dayjs(log.created_at).format('DD-MM-YYYY HH:mm')}</div>
                                                    <h6>Modificado por:</h6>
                                                    <div>
                                                        {printNameAndRole(log.user)}
                                                    </div>
                                                    <h6>Productos:</h6>
                                                    <OrderItems order={{items:items}} onReloadRequest={this.loadOrder}></OrderItems>
                                                </div>
                                                )

                                        })
                                    }
                                    <Modal.Footer>
                                        <Button onClick={() => {
                                                    this.setState({showChangesModal:false})
                                                    this.onProductSelected(this.state.showChangesModal)
                                                }}>
                                            Cerrar
                                        </Button>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Modal>

                            <Card.Body className={'totals text-right'}>
                                <Card.Title>
                                    Totales sin validar
                                </Card.Title>
                                <div className={'t-row d-flex justify-content-end'}>
                                    <div className={'title'}>
                                        Sub Total:
                                    </div>
                                    <div className={'amount'}>
                                        {money(this.state.order.subTotal)}
                                    </div>
                                </div>
                                <div className={'t-row d-flex justify-content-end'}>

                                    <div className={'title'}>
                                        Impuestos:
                                    </div>
                                    <div className={'amount'}>
                                        {money(this.state.order.totalTax)}
                                    </div>
                                </div>
                                <div className={'t-row d-flex justify-content-end'}>
                                    <div className={'title'}>
                                        Envío:
                                    </div>
                                    <div className={'amount'}>
                                        {money(this.state.order.totalShipping)}
                                    </div>
                                </div>
                                <div className={'t-row d-flex justify-content-end'}>
                                    <div className={'title'}>
                                        Propina:
                                    </div>
                                    <div className={'amount'}>
                                        {money(displayTotals.parsedGratuityTotal)}
                                    </div>
                                </div>
                                <div className={'t-row d-flex justify-content-end'}>

                                    <div className={'title'}>
                                        Descuentos:
                                    </div>
                                    <div className={'amount'}>
                                        {money(this.state.order.totalDiscount)}
                                    </div>
                                </div>
                                <div className={'t-row d-flex justify-content-end'}>

                                    <div className={'title'}>
                                        Total:
                                    </div>
                                    <div className={'amount'}>
                                        {money(this.state.order.total)}
                                    </div>
                                </div>

                            </Card.Body>
                            <NPIf condition={
                                ['amount_validated', 'on_route',
                                    'delivering', 'finished', 'archived',
                                    'cancelled'].includes(this.state.order.status)
                            }>
                                <Card.Body className={'totals text-right'}>
                                    <Card.Title>
                                        Totales validados
                                    </Card.Title>
                                    <div className={'t-row d-flex justify-content-end'}>
                                        <div className={'title'}>
                                            Sub Total:
                                        </div>
                                        <div className={'amount'}>
                                            {money(this.state.order.validatedSubTotal)}
                                        </div>
                                    </div>
                                    <div className={'t-row d-flex justify-content-end'}>

                                        <div className={'title'}>
                                            Impuestos:
                                        </div>
                                        <div className={'amount'}>
                                            {money(this.state.order.validatedTotalTax)}
                                        </div>
                                    </div>
                                    <div className={'t-row d-flex justify-content-end'}>
                                        <div className={'title'}>
                                            Envío:
                                        </div>
                                        <div className={'amount'}>
                                            {money(this.state.order.totalShipping)}
                                        </div>
                                    </div>
                                    <div className={'t-row d-flex justify-content-end'}>
                                        <div className={'title'}>
                                            Propina:
                                        </div>
                                        <div className={'amount'}>
                                            {money(displayTotals.parsedGratuityTotal)}
                                        </div>
                                    </div>
                                    <div className={'t-row d-flex justify-content-end'}>

                                        <div className={'title'}>
                                            Descuentos:
                                        </div>
                                        <div className={'amount'}>
                                            {money(this.state.order.totalDiscount)}
                                        </div>
                                    </div>
                                    <div className={'t-row d-flex justify-content-end'}>

                                        <div className={'title'}>
                                            Total:
                                        </div>
                                        <div className={'amount'}>
                                            {money(this.state.order.validatedTotal)}
                                        </div>
                                    </div>

                                </Card.Body>
                            </NPIf>

                        </Card>
                    </Col>
                </Row>
                {/*Free Shipping*/}
                <Row className={'mt-4'}>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>
                                    Envío gratis
                                </Card.Title>
                                {
                                    this.state.order?.freeShipping?.hasFreeShipping ?
                                        `Esta orden tuvo envío gratis por cumplir el mínimo de ${money(this.state.order?.freeShipping?.freeShippingCondition?.minPurchaseAmount)}` :
                                        'Esta orden no tuvo envío gratis'
                                }
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                {/*Coupons table*/}
                <Row className={'mt-4'}>
                    <OrderCoupons coupons={order.coupons} ></OrderCoupons>
                </Row>
                {/*Discounts table*/}
                <Row className={'mt-4'}>
                    <OrderDiscounts discounts={this.state.discounts}></OrderDiscounts>
                </Row>
                <Row className={'mt-4'}>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>Dirección de entrega</Card.Title>
                                <Row>
                                    <Col>
                                        <a href={`https://www.google.com/maps/search/?api=1&query=${shipping.latitude},${shipping.longitude}`}
                                           target={'_blank'}>
                                            <StaticMapDashboard latitude={shipping.latitude}
                                                                longitude={shipping.longitude}
                                                                zoom={17}
                                                                height={'170px'}>
                                            </StaticMapDashboard>
                                        </a>
                                    </Col>
                                    <Col>
                                        {this.renderShipping()}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>Datos del cliente</Card.Title>
                                <Avatar user={user}></Avatar>
                                <div className={'t-row d-flex'}>
                                    <div className={'title'}>
                                        E-mail
                                    </div>
                                    <div className={'amount'}>
                                        {user.email}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    order.requiresInvoice ?
                        <Row className={'mt-4'}>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className={'text-capitalize'}>Datos de facturación</Card.Title>
                                        <div>
                                            {
                                                order.invoiceData.rfc
                                            }
                                            <br/>
                                            {
                                                order.invoiceData.name
                                            }
                                            <br/>
                                            {
                                                order.invoiceData.email
                                            }
                                            <br/>
                                            {
                                                order.invoiceData.address
                                            }
                                            <br/>
                                            {
                                                order.invoiceData.cfdiUsage
                                            }
                                            <br/>
                                            {
                                                order.invoiceData.fiscalRegime
                                            }
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        : null
                }


                <Row className={'mt-4 mb-4'}>
                    <MPStatus orderId={orderId}></MPStatus>
                    <OrderRefunds orderId={orderId}></OrderRefunds>
                </Row>
                <Row className={'mt-4'}>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>Números de guía</Card.Title>
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="name">
                                    <Col sm={6} className={'d-flex'}>
                                        <DropdownButton id="dropdown-basic-button" title={this.state.parcelName|| 'Paquetería'} variant={'primary'}>
                                            {
                                                parcels.map((parcel) =>{
                                                    return <DropdownItem key={parcel} onClick={() => this.setState({parcelName:parcel.name})}>
                                                        {parcel.name}
                                                    </DropdownItem>
                                                })
                                            }

                                        </DropdownButton>
                                        <Form.Control type="text"
                                                      placeholder="Número de guía"
                                                      value={this.state.parcelNumber}
                                                      onChange={event => this.setState({parcelNumber:event.target.value})}/>
                                        {
                                            this.state.isAddingParcel ?
                                                <Spinner animation={'border'} color={'primary'}></Spinner> :
                                                <Button variant={'link'} onClick={() =>{this.addParcel()}}
                                                        disabled={!this.state.parcelNumber.trim() || !this.state.parcelName.trim()}>
                                                    Agregar
                                                </Button>
                                        }

                                    </Col>

                                </Form.Group>
                            </Card.Body>
                            <OrderParcels parcels={this.state.order.parcels || []} ></OrderParcels>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-4 mb-4'}>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>Historial de la orden</Card.Title>
                                {
                                    logs.map((log,index) => {
                                        return (
                                            <Row className={'mb-4'} key={`log-key-${index}`}>
                                                <Col sm={2} style={{display: 'flex', alignItems: 'center'}}>
                                                    <b>{dayjs(log.created_at).format('DD MMM HH:mm, YYYY')}</b>
                                                </Col>
                                                <Col sm={8} style={{display: 'flex', alignItems: 'center'}}>
                                                    <span style={{fontSize: 25, marginRight: 10}}>
                                                        {
                                                            log.logType === 'viewed' ? '👀' : ''
                                                        }
                                                    </span>
                                                    {log.log}
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )

    }

    renderShipping() {
        let shipping = this.state.order.shipping;
        if (!shipping) {
            return (null);
        }
        return (
            <div>
                <div>
                    <b>{shipping.name} {shipping.lastName}</b>
                </div>
                <div>
                    {shipping.phone}
                </div>
                <div>
                    {shipping.address}
                </div>
                <div>
                    {[shipping.address2, shipping.borough].filter((v) => v).join(',')}
                </div>
                <div>
                    {shipping.state}
                </div>
                <div>
                    {shipping.zipCode}
                </div>
                <div>
                    Referencia: {shipping.reference}
                </div>
            </div>
        )

    }

    renderPaymentStatus() {
        let status  = this.state.order.paymentStatus;
        let variant = '';
        let txt     = '';
        switch (status) {
            case 'paid':
                variant = 'success';
                txt     = 'Pagado';
                break;
            case 'pending':
                variant = 'warning';
                txt     = 'Pendiente de pago';
                break;
            default:
                variant = 'danger';
                break;
        }
        return (
            <Badge bg={variant}>{txt}</Badge>
        )

    }
    renderInvoice(){
        const requiresInvoice = this.state.order.requiresInvoice;
        if(requiresInvoice){
            return <span className={'text-success'}><b>Si</b></span>
        }else{
            return 'No'
        }
    }
    renderOrderStatus() {
        let status  = this.state.order.status;
        let variant = '';
        let txt     = '';
        switch (status) {
            case 'processing':
                variant = 'info';
                txt     = 'Procesando';
                break;
            case 'received':
                variant = 'info';
                txt     = 'Recibida';
                break;
            case 'amount_validated':
                variant = 'info';
                txt     = 'Monto validado';
                break;
            case 'on_route':
                variant = 'info';
                txt     = 'En camino';
                break;
            case 'delivering':
                variant = 'info';
                txt     = 'Entregando';
                break;
            case 'delivered':
                variant = 'info';
                txt     = 'Entregado';
                break;
            case 'finished':
                variant = 'info';
                txt     = 'Terminado';
                break;
            case 'cancelled':
                variant = 'danger';
                txt     = 'Cancelada';
                break;
            default:
                variant = 'info';
                txt     = "---";
                break;
        }
        return (
            <Badge bg={variant}>{txt}</Badge>
        )
    }

    renderPaymentMethod() {
        switch (this.state.order.paymentMethod) {
            case 'card':
                return 'Tarjeta de Crédito/Débito'
            case 'cash':
                return 'Efectivo'
            case 'pos':
                return 'Terminal Punto de Venta'
            case 'mercado-pago':
                return 'Mercado Pago'
        }
    }
}
const getRole  = (user) =>{
    let role = 'Usuario';
    if(user.roles.includes('super_admin')) {
        role = 'Super admininstrador';
    }else if(user.roles.includes('admin')){
        role = 'Admininstrador';
    }else if(user.roles.includes('operator')){
        role = 'Operador'
    }else if(user.roles.includes('marketing')){
        role = 'Marketing'
    }else if(user.roles.includes('user')) {
        role = 'Cliente'
    }
    return role;

}
const printNameAndRole = (user) =>{
    const role = getRole(user);
    const name = [user.name, user.last_name].filter((n) => n).join(' ');
    return `${name} (${role}, ${user.email})`;
}
export default withRouter(Order);

/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from "react";
import {withRouter} from "react-router";
import NbioApi from "../../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import {money} from "../../lib/money/money";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import Swal from "sweetalert2";
import NPIf from "np-if";
import Lightbox from "react-awesome-lightbox";

//style
import '../../scss/components/ui/orderitems.scss';
//components
import SortBtns from "../ui/SortBtns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";


class OrderItems extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
            showModal:false,
            showMenuReject:false,
            selectedItem: {},
            comment:'',
            showLightBox:false,
            lightBoxImage:''
        }

        this.handleClose                    = this.handleClose.bind(this);
        this.handleShow                     = this.handleShow.bind(this);
        this.handleCloseMenu                = this.handleCloseMenu.bind(this);
        this.handleOpenMenu                 = this.handleOpenMenu.bind(this);
        this.onSetComment                   = this.onSetComment.bind(this);
        this.confirmApprovedPrescription    = this.confirmApprovedPrescription.bind(this);
        this.confirmRejectedPrescription    = this.confirmRejectedPrescription.bind(this);
        this.viewPrescription               = this.viewPrescription.bind(this);
        this.validatePrescription           = this.validatePrescription.bind(this);
        this.renderPrescriptionStatus       = this.renderPrescriptionStatus.bind(this);
    }

    handleClose(){
        this.setState({showModal:false});
    }

    handleShow(){
        this.setState({showModal:true});
    }

    handleOpenMenu(){
        this.setState({showMenuReject:true});
    }

    handleCloseMenu(){
        this.setState({showMenuReject:false})
    }

    onSetComment(comment){
        if(comment){
            this.setState({comment:comment}, () => {
                this.handleCloseMenu();
                this.confirmRejectedPrescription();
            });
        }
    }

    confirmRejectedPrescription(){
        let timerInterval;
        Swal.fire({
            title: 'Rechazando en',
            html: '... <b></b> segundos.',
            timer: 10000,
            timerProgressBar: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Rechazar ahora',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#f5525d',
            allowOutsideClick: false,
            heightAuto:false,
            didOpen: () => {
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = Math.floor(Swal.getTimerLeft() / 1000)
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                // console.log('Prescription rejected');
                this.validatePrescription(false,this.state.comment);
            } else if(result.dismiss === Swal.DismissReason.cancel){
                // console.log('Prescription rejected CANCEL');
            } else if(result.dismiss === Swal.DismissReason.timer){
                this.validatePrescription(false,this.state.comment);
            }
        })
    }

    confirmApprovedPrescription(){
        let timerInterval;
        Swal.fire({
            title: 'Aprobando en',
            html: '... <b></b> segundos.',
            timer: 10000,
            timerProgressBar: true,
            confirmButtonText: 'Aprobar ahora',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#4dda85',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false,
            didOpen: () => {
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = Math.floor(Swal.getTimerLeft() / 1000)
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                this.validatePrescription(true,'');
            } else if(result.dismiss === Swal.DismissReason.cancel){
                console.log('Prescription approved CANCEL');
            } else if(result.dismiss === Swal.DismissReason.timer){
                this.validatePrescription(true,'');
            }
        })
    }

    validatePrescription(validated,comment){
        const orderId = this.props.order._id;
        const itemId = this.state.selectedItem._id;
        NbioApi.orders.validatePrescription(orderId,itemId,validated,comment).then(res => {
            //Clear selected item, close prescription modal, show response swal and reaload the order view
            this.setState({selectedItem:{},comment:''});
            let swalTxt = 'Receta rechazada';
            if(validated){
                swalTxt = 'Receta aprobada';
            }
            Swal.fire({
                title:swalTxt,
                confirmButtonColor: '#4dda85',
                willClose:() => {
                    this.handleClose();
                    this.props.onReloadRequest()
                },
                heightAuto:false
            });
        }).catch((ex) => {
            console.log(ex);
        })
    }

    viewPrescription(e,row){
        e.preventDefault();
        this.setState({selectedItem:row}, () => {
            this.handleShow();
        });
    }

    renderPrescriptionStatus(prescription){
        let txtColor = 'text-warning';
        let txt = 'Falta validar';

        if(prescription.isPrescriptionValidated){
            txtColor = prescription.isPrescriptionValid ? 'text-success' : 'text-danger';
            txt = prescription.isPrescriptionValid ? 'Aprobada' : 'Rechazada';
        }

        return(
            <label className={txtColor}><b>{txt}</b></label>
        )
    }
    renderLightBox(imageUrl){
        if(this.state.showLightBox){
            return (<Lightbox image={imageUrl} onClose={() => this.toggleLightBox()}></Lightbox>)
        }else{
            return null;
        }
    }
    toggleLightBox(){
        this.setState({showLightBox:!this.state.showLightBox})
    }
    
    render() {
        const columns  = [
            {
                dataField: 'imageUrlThumbnail',
                text: 'Imagen',
                formatter: (value, row) => {
                    return (
                        <img src={value} width={75}/>
                    )
                },
            },
            {
                dataField: 'isControlled',
                text: '¿Es controlado?',
                formatter: (value) => {
                    let txt = value ? "Si" : "No";
                    return txt;
                },
            },
            {
                dataField: 'prescription',
                text: 'Id de la receta',
                formatter: (value, row) => {
                    if(value){
                        return (value._id);
                    }else{
                        return 'No aplica';
                    }
                },
            },
            {
                dataField: 'isPrescriptionValid',
                text: 'Estado de la receta',
                formatter: (value, row) => {
                    let txtColor = 'text-warning';
                    let txt = 'Falta validar';
                    if(row.isControlled){
                        if(row.isPrescriptionValidated){
                            txtColor = value ? 'text-success' : 'text-danger';
                            txt = value ? 'Aprobada' : 'Rechazada';
                        }
                        return (<a href={''} onClick={(e) => this.viewPrescription(e,row)}
                                  className={txtColor}><b>{txt}</b>
                                </a>)
                    }else{
                        return 'No aplica';
                    }
                },
            },
            {
                dataField: 'description',
                text:'Producto',
                headerStyle:() =>{
                    return {
                        width:'250px'
                    }
                },
                formatter:(value,row) => {
                        return(
                            <div>
                                <div>{value}</div>
                                <div>
                                    <b>
                                        SKU: {row.SKU}
                                    </b>
                                </div>
                                <div>
                                    <b>
                                        Ingrediente Activo: {row.activeIngredient}
                                    </b>
                                </div>
                            </div>
                        )
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'adName',
                text:'Anuncio',
                headerStyle:() =>{
                    return {
                        width:'250px'
                    }
                },
                formatter:(value,row) => {
                    if(value){
                        return(
                            <div>
                                <a href={`/ads/${row.adId}`}>{value}</a>
                            </div>
                        )
                    }else{
                        return(
                            <div>
                              Ninguno
                            </div>
                        )
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'price',
                text:'Precio',
                formatter:(value,row) =>{
                    const discount = row.discount ? row.discount : null;
                    const originalPrice =  discount ? row.originalPrice : 0;
                    if(discount){
                        return (
                            <div>
                                <div className={'strikethrough'}>
                                    {money(originalPrice)}
                                </div>
                                <div className={'discounted-price'}>
                                    <b>{money(value)}</b>
                                </div>
                                <div>
                                    <small>
                                        <FontAwesomeIcon icon={"percentage"} />&nbsp;{discount.name}
                                    </small>
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div>
                                {money(value)}
                            </div>
                        )
                    }

                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'validatedPrice',
                text:'Precio validado',
                formatter:(value,row) =>{
                        return (
                            <div className={'validated-price'}>
                                {money(value)}
                            </div>
                        )
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'quantity',
                text:'Cantidad',
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'price',
                text:'Subtotal',
                formatter:(value,row) =>{
                    const discount = row.discount ? row.discount : null;
                    const originalPrice =  discount ? row.originalPrice : 0;
                    if(discount){
                        return (
                            <div>
                                <div className={'strikethrough'}>
                                    {money(originalPrice * row.quantity)}
                                </div>
                                <div className={'discounted-price'}>
                                    {money(value)}
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div>
                                {money(value * row.quantity)}
                            </div>
                        )
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'validatedPrice',
                text:'Subtotal validado',
                formatter:(value,row) =>{
                    const discount = row.discount ? row.discount : null;
                    const originalPrice =  discount ? row.originalPrice : 0;
                    if(discount){
                        return (
                            <div>
                                <div className={'strikethrough'}>
                                    {money(originalPrice * row.quantity)}
                                </div>
                                <div className={'discounted-price'}>
                                    {money(value)}
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div className={'validated-price'}>
                                {money(value * row.quantity)}
                            </div>
                        )
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            }
        ]

        const editModeColumns  = [
            {
                dataField: 'imageUrlThumbnail',
                text: 'Imagen',
                formatter: (value, row) => {
                    return (
                        <img src={value} width={75}/>
                    )
                },
            },
            {
                dataField: 'isControlled',
                text: '¿Es controlado?',
                formatter: (value) => {
                    let txt = value ? "Si" : "No";
                    return txt;
                },
            },

            {
                dataField: 'description',
                text:'Producto',
                headerStyle:() =>{
                    return {
                        width:'250px'
                    }
                },
                formatter:(value,row) => {
                    return(
                        <div>
                            <div>{value}</div>
                            <div>
                                <b>
                                    SKU: {row.SKU}
                                </b>
                            </div>
                            <div>
                                <b>
                                    Ingrediente Activo: {row.activeIngredient}
                                </b>
                            </div>
                        </div>
                    )
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'price',
                text:'Precio',
                formatter:(value,row) =>{
                    const discount = row.discount ? row.discount : null;
                    const originalPrice =  discount ? row.originalPrice : 0;
                    if(discount){
                        return (
                            <div>
                                <div className={'strikethrough'}>
                                    {money(originalPrice)}
                                </div>
                                <div className={'discounted-price'}>
                                    <b>{money(value)}</b>
                                </div>
                                <div>
                                    <small>
                                        <FontAwesomeIcon icon={"percentage"} />&nbsp;{discount.name}
                                    </small>
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div>
                                {money(value)}
                            </div>
                        )
                    }

                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'quantity',
                text:'Cantidad',
                sort: true,
                formatter:(value,row,index) =>{
                    return <div>
                        <ButtonGroup>
                            <Button variant={'secondary'} onClick={() => this.props.onDecreaseQty(row,index)}>-</Button>
                            <Form.Control type="text"
                                          style={{borderRadius:0,width:70,textAlign:'center'}}
                                          value={value}
                                          id={`qty_input_${index}`}
                                          onChange={(evt) => this.props.onChangeQty(row,index,evt.target.value)}
                                         />
                            <Button variant={'secondary'}  onClick={() => this.props.onIncreaseQty(row,index)}>+</Button>
                        </ButtonGroup>
                    </div>
                },
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'price',
                text:'Subtotal',
                formatter:(value,row) =>{
                    const discount = row.discount ? row.discount : null;
                    const originalPrice =  discount ? row.originalPrice : 0;
                    if(discount){
                        return (
                            <div>
                                <div className={'strikethrough'}>
                                    {money(originalPrice * row.quantity)}
                                </div>
                                <div className={'discounted-price'}>
                                    {money(value)}
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div>
                                {money(value * row.quantity)}
                            </div>
                        )
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'price',
                text:'',
                formatter:(value,row,index) =>{
                        return (
                            <div>
                                <Button variant={'link'}
                                        style={{color:'red'}}
                                        size={'sm'}
                                        onClick={() => this.props.onDeleteItem(row,index)}
                                >Eliminar</Button>
                            </div>
                        )
                },
                sort: false
            },
        ]
        const items = this.props.order.items ? this.props.order.items : [];
        const selectedItem = this.state.selectedItem ? this.state.selectedItem : {};
        const prescription = selectedItem.prescription ? selectedItem.prescription : {};
        const description = selectedItem.description ? selectedItem.description : '';
        const idPrescription = prescription._id ? prescription._id : '';
        const issueDate = prescription.issueDate ? prescription.issueDate : '';
        const documentId = prescription.documentId ? prescription.documentId : '';
        const imageUrl = prescription.imageUrl? prescription.imageUrl : '';
        const photoId = selectedItem.photoId || {};
        const imageFrontUrl = photoId.imageFrontUrl || null;
        const imageBackUrl = photoId.imageBackUrl || null;
        const editMode = this.props.editMode ? this.props.editMode : false;
        return(
            <>
                <BootstrapTable keyField='id'
                                data={items}
                                columns={editMode ? editModeColumns : columns}
                                noDataIndication={``}
                                bordered={false}/>

                {/*Prescription Modal*/}
                {this.renderLightBox(this.state.lightBoxImage)}
                <Modal
                    show={this.state.showModal}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    fullscreen={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Validar receta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                         <Container>
                             <Row>
                                 <Col xs={5} md={3}>
                                     <label><small>Medicamento:</small></label>
                                 </Col>
                                 <Col xs={7} md={9}>
                                     <label><b className={'text-primary'}>{description}</b></label>
                                 </Col>
                             </Row>
                             <Row>
                                 <Col xs={5} md={3}>
                                     <label><small>Estado de la receta: </small></label>
                                 </Col>
                                 <Col xs={7} md={9}>
                                     {this.renderPrescriptionStatus(selectedItem)}
                                 </Col>
                             </Row>
                             <NPIf condition={selectedItem.isPrescriptionValidated}>
                                 <NPIf condition={! selectedItem.isPrescriptionValid}>
                                     <Row>
                                         <Col xs={5} md={3}>
                                             <label><small>Razón de rechazo: </small></label>
                                         </Col>
                                         <Col xs={7} md={9}>
                                             <label><b>{selectedItem.prescriptionValidatorComment}</b></label>
                                         </Col>
                                     </Row>
                                 </NPIf>
                                 <Row>
                                     <Col xs={5} md={3}>
                                         <label><small>Revisado por: </small></label>
                                     </Col>
                                     <Col xs={7} md={9}>
                                         <label><b>{selectedItem.prescriptionValidatedBy}</b></label>
                                     </Col>
                                 </Row>
                             </NPIf>
                             <Row className='mt-2'>
                                 <Col sm={12} md={6} lg={8}>
                                     <div className={'image-preview'} style={{
                                         height:'400px',
                                         backgroundImage:`url(${imageUrl})`,
                                         backgroundPosition: 'center',
                                         backgroundSize: 'auto 400px',
                                         backgroundColor: '#0003',
                                         backgroundRepeat: 'no-repeat'
                                     }} onClick={() => {
                                         this.setState({
                                             lightBoxImage: imageUrl
                                         }, () =>{
                                             this.toggleLightBox();
                                         })
                                     }}>
                                         <div className={'label'}>
                                             Da click para abrir en pantalla completa
                                         </div>
                                     </div>
                                     <NPIf condition={photoId.documentType}>
                                         <div className={'image-preview mt-2'} style={{
                                             height:'400px',
                                             backgroundImage:`url(${imageFrontUrl})`,
                                             backgroundPosition: 'center',
                                             backgroundSize: 'auto 400px',
                                             backgroundColor: '#0003',
                                             backgroundRepeat: 'no-repeat'
                                         }} onClick={() => {
                                             this.setState({
                                                 lightBoxImage: imageFrontUrl
                                             }, () =>{
                                                 this.toggleLightBox();
                                             })
                                         }}>
                                             <div className={'label'}>
                                                 Da click para abrir en pantalla completa
                                             </div>
                                         </div>
                                         <div className={'image-preview mt-2'} style={{
                                             height:'400px',
                                             backgroundImage:`url(${imageBackUrl})`,
                                             backgroundPosition: 'center',
                                             backgroundSize: 'auto 400px',
                                             backgroundColor: '#0003',
                                             backgroundRepeat: 'no-repeat'
                                         }} onClick={() => {
                                             this.setState({
                                                 lightBoxImage: imageBackUrl
                                             }, () =>{
                                                 this.toggleLightBox();
                                             })
                                         }}>
                                             <div className={'label'}>
                                                 Da click para abrir en pantalla completa
                                             </div>
                                         </div>
                                     </NPIf>

                                 </Col>
                                 <Col sm={12} md={6} lg={4}>
                                     <div className={'d-flex flex-column'}>
                                         <label><small>Id de la receta:</small></label>
                                         <label><b>{idPrescription}</b></label>
                                     </div>
                                     <div className={'d-flex flex-column'}>
                                         <label><small>Cédula Profesional:</small></label>
                                         <label><b>{documentId}</b></label>
                                     </div>
                                     <div className={'d-flex flex-column mt-1'}>
                                         <label><small>Fecha de emisión:</small></label>
                                         <label><b>{issueDate}</b></label>
                                     </div>
                                     <div className={'d-flex flex-column mt-1'}>
                                         <label><small>Grupo de Medicamento</small></label>
                                         <label><b>{selectedItem.medicationGroup}</b></label>
                                     </div>
                                     <NPIf condition={photoId.documentType}>
                                         <hr></hr>
                                         <div className={'d-flex flex-column mt-1'}>
                                             <label><small>Tipo de identificación:</small></label>
                                             <label><b>{photoId.documentType}</b></label>
                                         </div>
                                         <div className={'text-danger'}>
                                             *El nombre de la receta debe de coincidir con el nombre en la identificación.
                                         </div>
                                     </NPIf>

                                 </Col>
                             </Row>
                         </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Dropdown autoClose="false"
                                  show={this.state.showMenuReject}
                                  onClick={(ev) => {
                                      ev.preventDefault();
                                      this.handleOpenMenu();
                        }}>
                            <Dropdown.Toggle  variant="danger" id="dropdown-rejects-prescription">
                                Rechazar receta
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="1"
                                               onClick={() => {
                                                   this.onSetComment('El medicamento no coincide');
                                               }}>
                                    El medicamento no coincide
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="2"
                                               onClick={() => this.onSetComment('La fecha es incorrecta')}>
                                    La fecha es incorrecta</Dropdown.Item>
                                <Dropdown.Item eventKey="3"
                                               onClick={() => this.onSetComment('La imagen es ilegible')}>
                                    La imagen es ilegible</Dropdown.Item>
                                <Dropdown.Item eventKey="4"
                                               onClick={() => this.onSetComment('Los datos proporcionados no coinciden')}>
                                    Los datos proporcionados no coinciden</Dropdown.Item>
                                <Dropdown.Item eventKey="5"
                                               onClick={() => this.onSetComment('Posible falsificación')}>
                                    Posible falsificación</Dropdown.Item>
                                <Dropdown.Item eventKey="6"
                                               onClick={() => this.onSetComment('El nombre en la identificación no coincide con la receta')}>
                                    El nombre en la identificación no coincide con la receta
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="7"
                                               onClick={() => this.onSetComment('La identificación es ilegible')}>
                                    La identificación es ilegible
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="8"
                                               onClick={() => this.onSetComment('El reverso de la identificación es inválido')}>
                                    El reverso de la identificación es inválido
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="9"
                                               onClick={() => this.onSetComment('Identificación falsa')}>
                                    Identificación falsa
                                </Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item eventKey="6" onClick={(ev) => ev.stopPropagation()}>
                                    <FormControl placeholder={'Otra'}
                                                 type={'text'}
                                                 value={this.state.comment}
                                                 onChange={(e) => {
                                                     this.setState({comment: e.target.value})
                                                 }}
                                                 onKeyDown={(e) => {
                                                     e.stopPropagation();
                                                     if(e.key === 'Enter'){
                                                         e.preventDefault();
                                                         this.onSetComment(this.state.comment)
                                                     }
                                                 }}
                                    >
                                    </FormControl>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <Button variant="success" onClick={this.confirmApprovedPrescription}>Aprobar receta</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
export default withRouter(OrderItems);
